// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-provisionscase-js": () => import("./../src/pages/provisionscase.js" /* webpackChunkName: "component---src-pages-provisionscase-js" */),
  "component---src-pages-stocknotifycase-js": () => import("./../src/pages/stocknotifycase.js" /* webpackChunkName: "component---src-pages-stocknotifycase-js" */)
}

